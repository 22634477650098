import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import CommonH2 from '../../components/atoms/commonH2'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import CategoryCard from '../../components/molecules/categoryCard'
import Layout from '../../components/organisms/layout'
import BreakPoints from '../../styles/breakPoints'
import { CategoryKind } from '../../types/CategoryKind'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  .category-card:first-of-type {
    height: 364px;
  }
  ${BreakPoints.medium} {
    .category-card:first-of-type {
      height: auto;
    }
  }
`

type IProps = PageProps<GatsbyTypes.GuidePageQuery>

/**
 * ご利用ガイドページ
 * @constructor
 */
const GuidePage = ({ data, location }: IProps) => {
  // GraphQLで取得したデータを保持
  const items = data.awsGraphQL.listHelpCategories?.items || []

  const paths: ITopicPath[] = [
    { title: 'TOP', link: useRouteParam('/help') },
    { title: 'ご利用ガイド' },
  ]
  return (
    <Layout location={location} paths={paths} categoryKind={CategoryKind.GUIDE}>
      <SEO title="ご利用ガイド" />
      <CommonH2 label="ご利用ガイド" />
      <p className="txt-center mt-020 mb-020">
        基本操作方法を含めたキンクラの機能、操作方法をご案内します。
      </p>
      <Wrapper>
        {[...items]
          .sort((a, b) => (a?.sortNo || 0) - (b?.sortNo || 0))
          .filter((item) => !item?._deleted)
          .map((item) => {
            const helpCategoryId = item?.helpCategoryId || ''
            const slug = item?.slug || ''
            const title = item?.title || ''
            return (
              <CategoryCard
                key={`category-card-${helpCategoryId}`}
                helpCategoryId={helpCategoryId}
                categoryTitle={title}
                slug={slug}
                limit={10}
                visibleAllLink
              />
            )
          })}
      </Wrapper>
    </Layout>
  )
}

export default GuidePage

export const pageQuery = graphql`
  query GuidePage {
    awsGraphQL {
      listHelpCategories(filter: { categoryKind: { eq: 2 } }) {
        items {
          helpCategoryId
          sortNo
          title
          slug
          categoryKind
          _deleted
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
